// BR
export const locale = {
  lang: "br",
  data: {
    TRANSLATOR: {
      SELECT: "Selecione sua língua",
    },
    USER_NOTIFICATION: {
      IMPORT_IN_PROGRESS: "Importação em progresso",
      IMPORT_COMPLETED: "Importação concluída",
      FAILED_IMPORT: "Falha na importação",
      NEW_NOTIFICATIONS: "Novas",
      ALERTS: "Alertas",
      EVENTS: "Eventos",
      ALL_CAUGHT_UP: "Tudo em dia!",
      NO_NEW_NOTIFICATIONS: "Não há novas notificações.",
      CLEAR_ALL: "Limpar tudo",
    },
    VALIDATION: {
      WAS_ENTERED_CORRECT: " foi adicionado corretamente.",
      WAS_ENTERED_CORRECT_FEMININE: " foi adicionada corretamente.",
      IS_REQUIRED: " é obrigatório.",
      IS_REQUIRED_FEMININE: " é obrigatória.",
      IS_INVALID: " é inválido.",
      IS_INVALID_FEMININE: " é inválida.",
      SHOULD_HAVE_AT_LEAST_1_SYMBOL: " deve ter pelo menos 1 símbolo.",
      SHOULD_HAVE_AT_LEAST_3_SYMBOLS: " deve ter pelo menos 3 símbolos.",
      SHOULD_HAVE_MAXIMUM_100_SYMBOLS: " deve ter no máximo 100 símbolos.",
      SHOULD_HAVE_A_MINIMUM_VALUE_OF_ONE: " deve ter um valor mínimo de 1.",
    },
    KEYWORDS: {
      CONFIRM: "Confirmar",
      PER_INTERVAL: "Por intervalo",
      CLASS: "Classes",
      UNIQUE: "Único",
      NOT_DEFINED: "Não definido",
      TYPE: "Tipo",
      CONCLUDE: "Concluir",
      ADD: "Adicionar",
      REMOVE: "Remover",
      ALL: "Todos",
      NONE: "Nenhum",
      ID: "ID",
      EDIT: "Editar",
      CREATE: "Criar",
      SAVE: "Salvar",
      CANCEL: "Cancelar",
      DELETE: "Deletar",
      BACK: "Voltar",
      RESET: "Resetar",
      FIRSTNAME: "Primeiro nome",
      LASTNAME: "Último nome",
      STATUS: "Status",
      SUSPENDED: "Suspenso",
      ACTIVE: "Ativo",
      PENDING: "Pendente",
      INACTIVE: "Inativo",
      FETCH: "Buscar",
      UPDATE_STATUS: "Atualizar status",
      UPDATE_CONFIG: "Atualizar Configuração",
      ENABLED: "Ativado",
      DISABLED: "Desativado",
      EXPIRED: "Expirado",
      UNLOCKED: "Desbloqueado",
      LOCKED: "Bloqueado",
      FILTER_BY_STATUS: "Filtrar por status",
      BY_STATUS: " por status",
      FILTER_BY_TYPE: "Filtrar por tipo",
      SEARCH_IN_ALL_FIELDS: "Pesquisar em todos os campos",
      FILTER: "Filtro",
      BY_TYPE: " por tipo",
      BUSINESS: "Negócio",
      INDIVIDUAL: "Individual",
      SEARCH: "Pesquisar",
      IN_ALL_FIELDS: "em todos os campos",
      SELECTED_RECORDS_COUNT: "Contagem de registros selecionados:",
      DELETE_ALL: "Apagar tudo",
      DELETE_SELECTED: "Excluir selecionados",
      FETCH_SELECTED: "Buscar selecionado",
      ACTIONS: "Ações",
      CREATED: "Data de Criação",
      VERSION: "Versão",
      DISABLE: "Desabilitar",
      ENABLE: "Habilitar",
      UPDATED: "Data da Última Atualização",
      MAP: "Mapa",
      SELECT: "Selecione um ",
      SELECT_FEMININE: "Selecione uma",
      NEW: "Novo ",
      NEW_FEMININE: "Nova ",
      NAME: "Nome",
      USERNAME: "Nome de Usuário",
      DISPLAY_NAME: "Nome de Exibição",
      DESCRIPTION: "Descrição",
      LAYER: "Camada",
      USER: "Usuário",
      DISABLE_ALL: "Desabilitar todos os selecionados",
      VIEW: "Ver",
      CLOSE: "Fechar",
      CATEGORY: "Categoria",
      ORDER: "Ordem",
      DEFAULT: "Padrão",
      CUSTOM: "Customizada",
      VERIFY: "Verificar",
      UNVERIFY: "Desverificar",
      VERIFIED: "Verificado",
      UNVERIFIED: "Não Verificado",
      DOWNLOAD: "Download",
    },
    FILE_MANAGEMENT: {
      RENAME: "Renomear",
      COPY_LINK: "Copiar link",
      CLICK_OR: "Clique ou",
      DRAG_THE: "arraste o",
      FILE_HERE: "arquivo aqui",
      LABELS_PLACEHOLDER: "Etiquetas separadas por ; Ex(ufv;eol;janeiro)",
      DISPOSAL_IN_DAYS: "Eliminação em dias",
      LABELS: "Etiquetas",
      CONTEXT: "Contexto",
      FILENAME: "Nome do arquivo",
      SIZE: "Size",
      MODIFIED: "Modificado",
      STATUS: "Status",
      DELETE_FILE: "Deletar arquivo",
      FILE_IS_BEING_DELETED: "Arquivo está sendo deletado",
      ARE_YOU_SURE_TO_DELETE_THIS_FILE:
        "Tem certeza que deseja deletar este arquivo?",
    },
    MENU: {
      DASHBOARD: "Painel de controle",
      ECOMAP: "EcoMap",
      EMAP: "e-Map",
      EBI: "e-BI",
      LEADS: "Leads",
      BACKOFFICE: "Backoffice",
      PLATFORM: "Plataforma",
      QUICK_SEARCH: "Pesquisar",
      NOTIFICATION: "Notificações",
      SUBHEADER: {
        TODAY: "Hoje",
      },
      USER_PROFILE: {
        CHAT: "Bate-Papo",
        FOLLOW: "Seguir",
        EMAIL: "Email",
        PHONE: "Telefone",
        PROFILE_OVERVIEW: "Visão geral do perfil",
        PERSONAL_INFORMATION: "Informação pessoal",
        CHANGE_PASSWORD: "Mudar senha",
        EMAIL_SETTINGS: "Definições de Email",
        SAVED_CREDIT_CARD: "Cartão de crédito salvo",
        ADDRESS: "Endereços do usuário",
        LOGIN_ACTIVITY: "Atividade de login",
        DESCRIPTION_ACTIVITY: "Por data e endereço IP",
        PERSONAL_INFO: "Informações pessoais",
        UPDATE_PERSONAL_INFO: "Atualize suas informações pessoais",
        SAVE_CHANGES: "Salvar mudanças",
        CANCEL: "Cancelar",
        CUSTOMER_INFO: "Informação do cliente",
        AVATAR: "Avatar",
        AVATAR_TIP: "Tipos de arquivo permitidos: png, jpg, jpeg.",
        REAL_NAME: "Nome",
        LAST_NAME: "Sobrenome",
        COMPANY_NAME: "Nome da empresa",
        COMPANY_NAME_TIP:
          "Se você deseja que suas faturas sejam endereçadas a uma empresa. Deixe em branco para usar seu nome completo.",
        CONTACT_INFO: "Informações de contato",
        CONTACT_PHONE: "Telefone de contato",
        CONTACT_PHONE_TIP:
          "Nunca compartilharemos seu telefone com mais ninguém",
        EMAIL_ADDRESS: "Endereço de E-mail",
        COMPANY_SITE: "Site da empresa",

        CHANGE_PASSWORDS: "Mudar senha",
        CHANGE_YOUR_ACCOUNT_PASSWORD: "Altere a senha da sua conta",
        CURRENT_PASSWORD: "Senha atual",
        CURRENT_PASSWORD_WAS_ENTERED_CORRECT:
          "A senha atual foi digitada corretamente",
        CURRENT_PASSWORD_IS_REQUIRED: "A senha atual é obrigatória",
        NEW_PASSWORD: "Nova senha",
        VERIFY_PASSWORD: "Verifique a senha",
        PASSWORD_CONFIRMATION_WAS_ENTERED_CORRECT:
          "A confirmação da senha foi digitada corretamente",
        PASSWORD_AND_CONFIRM_PASSWORD_DIDNT_MATCH:
          "'Senha' e 'Confirmar senha' não correspondem",

        EMAIL_SETTINGS_2: "Definições de Email",
        EMAIL_SETTINGS_TIP: "Atualize suas definições de Email",
        SETUP_EMAIL_NOTIFICATION: "Configure suas notificações de Email",
        EMAIL_NOTIFICATION: "Notificações de Email",
        SEND_COPY_TO_PERSONAL_EMAIL: "Enviar copia para seu Email pessoal",
        ACTIVITY_RELATED_EMAILS: "Email relacionado a atividade",
        WHEN_TO_EMAIL: "Quando enviar Email",
        YOU_HAVE_NEW_NOTIFICATIONS: "Você tiver notificações",
        YOU_ARE_SENT_A_DIRECT_MESSAGE: "Você enviou uma mensagem direta",
        SOMEONE_ADDS_YOU_AS_A_CONNECTION:
          "Alguém lhe adicionou como uma conexão",
        WHEN_TO_ESCALATE_EMAIL: "Quando escalar emails",
        UPON_NEW_ORDER: "Por emails novos",
        NEW_MEMBERSHIP_APPROVAL: "Aprovação de um novo membro",
        MEMBER_REGISTRATION: "Registro de membro",

        UPDATES_FROM_KEENTHEMES: "Atualizações de keenthemes",
        EMAIL_YOU_WITH: "Enviar email com",
        NEWS_ABOUT_KEENTHEMES_PRODUCTS_AND_FEATURE_UPDATES:
          "Notícias sobre produtos Keenthemes e atualizações de recursos",
        TIPS_ON_GETTING_MORE_OUT_OF_KEEN:
          "Dicas para tirar mais proveito do Keen",
        THINGS_YOU_MISSED_SINCE_YOU_LAST_LOGGED_INTO_KEEN:
          "Coisas que você perdeu desde o último login no Keen",
        NEWS_ABOUT_METRONIC_ON_PARTNER_PRODUCTS_AND_OTHER_SERVICES:
          "Notícias sobre a metronic em produtos de parceiros e outros serviços",
        TIPS_ON_METRONIC_BUSINESS_PRODUCTS:
          "Dicas sobre produtos empresariais Metronic",

        USER_ADDRESS: "Endereço do usuário",
        NEW_ADDRESS: "Novo endereço",
        ADDRESS_1: "Endereço 1",
        PRIMARY: "Primário",
        DELETE: "Deletar",
        EDIT: "Editar",

        CREDIT_CARDS_LIST: "Lista de cartões de crédito",
        NEW_CREDIT_CARD: "Novo cartão de crédito",

        CREDIT_CARD: "Cartão de crédito",
        CARD_MONTH_YEAR: "MM/AAAA",
        CARD_CVV: "CVV",
        CARD_EXPIRATION_DATE: "Data de vencimento",
        CARD_NUMBER: "Número do cartão",
        CARD_NAME: "Nome no cartão",
        SAVE: "Salvar",
        CREATE: "Criar",

        VIEW_ORDER: "Detalhes do pedido",
        NUMBER: "Número",
        DATE: " Data",
        MY_REQUESTS: "Meus Pedidos",
        USER_EMAIL: "Email do usuário",
        ORDER_NUMBER: "Número do pedido",

        NEW_USER_ADDRESS: {
          CREATE: "Criar",
          EDIT: "Editar",
          USER_ADDRESS: "endereço do usuário",
          STREET: "Rua",
          STREET_WAS_ENTERED_CORRECT: "Nome da rua foi adicionado corretamente",
          STREET_IS_REQUIRED: "Nome da rua é obrigatório",
          STREET_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
            "Nome da rua deve ter no minimo 3 símbolos",
          STREET_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
            "Nome da rua deve ter no máximo 100 símbolos",
          NUMBER: "Número",
          NUMBER_WAS_ENTERED_CORRECT: "O número foi adicionado corretamente",
          NUMBER_IS_REQUIRED: "Número é obrigatório",
          NUMBER_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
            "Número deve ter no minimo 3 símbolos",
          NUMBER_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
            "Número deve ter no máximo 100 símbolos",
          COMPLEMENT: "Complemento",
          COMPLEMENT_WAS_ENTERED_CORRECT:
            "Complemento foi adicionado corretamente",
          COMPLEMENT_IS_REQUIRED: "Complemento é obrigatório",
          COMPLEMENT_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
            "Complemento deve ter no minimo 3 símbolos",
          COMPLEMENT_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
            "Complemento deve ter no máximo 100 símbolos",
          NEIGHBORHOOD: "Bairro",
          NEIGHBORHOOD_WAS_ENTERED_CORRECT:
            "Bairro foi adicionado corretamente",
          NEIGHBORHOOD_IS_REQUIRED: "Bairro é obrigatório",
          NEIGHBORHOOD_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
            "Bairro deve ter no minimo 3 símbolos",
          NEIGHBORHOOD_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
            "Bairro deve ter no máximo 100 símbolos",
          POSTAL_CODE: "Código Postal",
          POSTAL_CODE_WAS_ENTERED_CORRECT:
            "Código Postal foi adicionado corretamente",
          POSTAL_CODE_IS_REQUIRED: "Código Postal é obrigatório",
          POSTAL_CODE_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
            "Código Postal deve ter no minimo 3 símbolos",
          POSTAL_CODE_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
            "Código Postal deve ter no máximo 100 símbolos",
          SELECT_STATE: "Selecione o estado",
          SELECT_STATE_TIP: "Por favor selecione o estado",
          SELECT_CITY: "Selecione a cidade",
          SELECT_CITY_TIP: "Por favor selecione a cidade",
          CANCEL: "Cancelar",
          SAVE: "Salvar",
        },
        PAYMENTS_LIST: "Lista de pagamentos",
        PAYMENTS: "Pagamentos",
        VIEW_PAYMENTS: "Visualizar pagamento",
        UNIQUE_PAYMENT_OF: "Pagamento único de",
        BY_MONTH: "por mês",
        SUBSCRIPTION_NAME: "Nome da assinatura",
        STATUS: "Status",
        PRICE: "Preço",
        TYPE: "Tipo",
        CREATED: "Data da criação",
        COUPON_CODE_NAME: "Nome do código do cupom",
        PAID_INSTALMENTS: "Parcelas pagas",
        TOTAL_INSTALMENTS: "Total de parcelas",
        BOLETO_URL: "URL do boleto",
        BOLETO_BAR_CODE: "Linha digitável do boleto",
        CREDIT_CARD_HASH: "Hash do cartão",
        ACTION: "Ação",
        CLOSE: "Fechar",
      },
      USER: {
        MESSAGE: "Mensagens",
        MY_PROFILE: "Meu perfil",
        DESCRIPTION_PROFILE: "Configurações da conta e mais",
        UPGRADE_PROFILE: "Atualizar",
        MY_MESSAGES: "Minhas mensagens",
        DESCRIPTION_MESSAGES: "Caixa de entrada e tarefas",
        MY_ACTIVITIES: "Minhas atividades",
        DESCRIPTION_ACTIVITIES: "Histórico e notificações",
        MY_TASKS: "Minhas tarefas",
        DESCRIPTION_TASKS: "Últimas tarefas e projetos",
        SIGN_OUT: "Sair",
        UPGRADE_PLAN: "Atualizar plano",
      },
    },
    SETTINGS: {
      DESCRIPTION:
        'O construtor de layout serve para ajudar a definir e configurar as especificações de layout de projeto preferidas e visualizá-lo em tempo real. As opções de layout configuradas serão salvas até que você as altere ou redefina. Para usar o construtor de layout, escolha as opções de layout e clique no botão "Visualizar" para visualizar as mudanças.',
      BUTTON_PREVIEW: "Visualizar",
      BUTTON_SAVE: "Salvar",
      BUTTON_RESET: "Resetar",
      VALUES: {
        FIXED: "Fixado",
        FLUID: "Fluido",
        DEFAULT: "Padrão",
        TAB: "Aba",
        TRANSPARENT: "Transparente",
        SOLID: "Sólido",
        SUBHEADER_1: "Subcabeçalho 1",
        SUBHEADER_POWERBI: "Subcabeçalho Power-Bi",
        SUBHEADER_3: "Subcabeçalho 3",
        SUBHEADER_4: "Subcabeçalho 4",
        SUBHEADER_LEADS: "Subcabeçalho Leads",
        SUBHEADER_6: "Subcabeçalho 6",
      },
      SITE: {
        SITE: "Site",
        ENTER_NAME: "Nome",
        ENTER_NAME_PLACEHOLDER: "Nome",
        LOGO: "Logotipo",
        LOGO_TIP: "São permitidos arquivos do tipo: png, jpg e jpeg.",
      },
      HEADER: {
        HEADER: "Cabeçalho",
        DESKTOP_FIXED_HEADER: "Cabeçalho fixo da área de trabalho",
        MOBILE_FIXED_HEADER: "Cabeçalho fixo do celular",
        WIDTH: "Largura do cabeçalho",
        WIDTH_TIP: "Selecione o tipo da ",
        DISPLAY_HEADER_MENU: "Menu de Cabeçalho",
        STATIC_HEADER_MENU: "Menu de Cabeçalho estático",
        EXHIBIT: "Exibir",
        EXHIBIT_THE: "Exibe o ",
        MENU_LAYOUT: "Layout do menu de Cabeçalho",
        SELECT: "Selecione o tipo do ",
        MENU_ARROWS: "Setas do menu de Cabeçalho",
        ALLOW: "Permite",
      },
      SUBHEADER: {
        SUBHEADER: "Subcabeçalho",
        DISPLAY_SUBHEADER: "Exibir Subcabeçalho",
        DISPLAY_SUBHEADER_TIP: "Exibe Subcabeçalho",
        FIXED_SUBHEADER: "Subcabeçalho fixo",
        FIXED_SUBHEADER_TIP:
          "Exibir Subcabeçalho fixo. Requer estilo 'Solido' do Subcabeçalho.",
        WIDTH: "Largura",
        WIDTH_TIP: "Selecione o tipo de largura do layout",
        STYLE: "Estilo do Subcabeçalho",
        LAYOUT: "Layout do Subcabeçalho",
        SELECT_TIP: "Selecione o",
      },
      CONTENT: {
        CONTENT: "Conteúdo",
        WIDTH: "Largura",
        WIDTH_TIP: "Selecione o tipo de largura do layout",
      },
      ASIDE: {
        ASIDE: "Menu Lateral",
        DISPLAY: "Exibir",
        DISPLAY_TIP: "Exibir Menu lateral",
        STATIC_ASIDE_MENU: "Menu lateral estático",
        STATIC_ASIDE_MENU_TIP: "Exibir menu lateral estático",
        FIXED: "Fixo",
        FIXED_TIP: "Definir o layout do menu lateral fixo",
        MINIMIZE: "Minimizar",
        MINIMIZE_TIP: "Permite o menu latera minimizar",
        DEFAULT_MINIMIZE: "Minimizar padrão",
        DEFAULT_MINIMIZE_TIP: "Permite o menu lateral minimizar por padrão",
        SCROLL: "Rolagem",
        SCROLL_TIP: "Permite a rolagem do menu lateral",
        SUBMENU_TOGGLE_DROPDOWN: "Lista suspensa de alternância",
        SUBMENU_TOGGLE_DROPDOWN_TIP:
          "Permite lista suspensa de alternância (Funciona apenas quando 'SCROLL' esta desativado. *Por padrão - o modo é 'acordeão').",
        RESTRICTION_TEMPLATES: "Modelos de Restrição",
      },
      FOOTER: {
        FOOTER: "Rodapé",
        FIXED_DESKTOP_FOOTER: "Rodapé fixo da área de trabalho",
        FIXED_DESKTOP_FOOTER_TIP: "Define rodapé fixo na área de trabalho.",
        WIDTH: "Largura",
        WIDTH_TIP: "Selecione o tipo de lagura do layout",
      },
      COLOR: {
        COLOR: "Cor",
        WHITE_COLOR: "Cor Branca",
        PRIMARY_COLOR: "Cor Primária",
        SECONDARY_COLOR: "Cor Secundária",
        SUCCESS_COLOR: "Cor de sucesso",
        INFO_COLOR: "Cor de informação",
        WARNING_COLOR: "Cor de aviso",
        DANGER_COLOR: "Cor de perigo",
        LIGHT_COLOR: "Cor clara",
        DARK_COLOR: "Cor escura",
        COLOR_TIP: "Selecione sua cor",
      },
    },
    ASIDE: {
      FILE_MANAGEMENT: "Gerenciamento de arquivos",
      FOLDERS: "Pastas",
      ORDERS: "Pedidos",
      USER_COMPANY: "Organizações",
      APPLY_STYLE: "Aplicar Estilos",
      USER_MANAGEMENT: "Gerenciamento de Usuários",
      USERS: "Usuários",
      ROLES: "Papel",
      SITES: "Sites",
      LEVELS: "Níveis",
      PLANS: "Planos",
      USER_MAP: "Mapa do Usuário",
      LOGINS: "Logins",
      USER_POWERBI: "e-BI",
      USER_POWERBI_TYPE: "Tipo de e-BI",
      USER_ACCESS_MANAGEMENT: "Gerenciamento de Acesso do Usuário",
      COMPANIES: "Empresas",
      USERS_ADDRESS: "Endereço dos Usuários",
      MAP_DATA_MANAGEMENT: "Gerenciamento de Mapas",
      BASE_MAP: "Mapa Base",
      LAYERS: "Camadas",
      GEOMETRY: "Dicionário de Estilo",
      FILTER_EXPRESSION: "Dicionário de Filtro",
      MAP_LIST: "Mapas",
      IMPORT_LAYER: "Importação de Camadas",
      ORIENTED_SEARCH: "Busca Orientada",
      PAYMENTS_MANAGEMENT: "Gerenciamento de Pagamentos",
      COUPONS: "Cupom de Desconto",
      PAYMENTS: "Pagamentos",
      SUBSCRIPTIONS: "Assinaturas",
      USER_PROFILE: "Perfil de Usuário",
      FEED_MANAGEMENT: "Gestão de Feed",
      TUTORIAL_MANAGEMENT: "Gestão de Tutorial",
      EXPORT_MANAGEMENT: "Gestão de Exportações",
      PRODUCTS: "Produtos",
      RESTRICTION_TEMPLATES: "Modelos de Restrição",
      MAP_ICONS: "Ícones do Mapa",
      SUBSCRIPTIONS_MANAGEMENT: "Gerenciamento de Assinaturas",
    },
    USER_PROFILE: {
      CHAT: "Bate-Papo",
      FOLLOW: "Seguir",
      EMAIL: "Email",
      PHONE: "Telefone",
      PROFILE_OVERVIEW: "Visão geral do perfil",
      LOGIN_ACTIVITY: "Atividade de login",
      DESCRIPTION_ACTIVITY: "Por data e endereço IP",
      PERSONAL_INFO: "Informações pessoais",
      UPDATE_PERSONAL_INFO: "Atualize suas informações pessoais",
      SAVE_CHANGES: "Salvar mudanças",
      CANCEL: "Cancelar",
      CUSTOMER_INFO: "Informação do cliente",
      AVATAR: "Avatar",
      AVATAR_TIP: "Tipos de arquivo permitidos: png, jpg, jpeg.",
      REAL_NAME: "Nome",
      LAST_NAME: "Sobrenome",
      COMPANY_NAME: "Nome da empresa",
      COMPANY_NAME_TIP:
        "Se você deseja que suas faturas sejam endereçadas a uma empresa. Deixe em branco para usar seu nome completo.",
      CONTACT_INFO: "Informações de contato",
      CONTACT_PHONE: "Telefone de contato",
      CONTACT_PHONE_TIP: "Nunca compartilharemos seu telefone com mais ninguém",
      EMAIL_ADDRESS: "Endereço de E-mail",
      COMPANY_SITE: "Site da empresa",
      CHANGE_PASSWORD: "Mudar senha",
      CHANGE_YOUR_ACCOUNT_PASSWORD: "Altere a senha da sua conta",
      CURRENT_PASSWORD: "Senha atual",
      NEW_PASSWORD: "Nova senha",
      VERIFY_PASSWORD: "Verifique a senha",
      PASSWORD_AND_CONFIRM_PASSWORD_DIDNT_MATCH:
        "'Senha' e 'Confirmar senha' não correspondem",
      EMAIL_SETTINGS: "Definições de Email",
      EMAIL_SETTINGS_TIP: "Atualize suas definições de Email",
      SETUP_EMAIL_NOTIFICATION: "Configure suas notificações de Email",
      EMAIL_NOTIFICATION: "Notificações de Email",
      SEND_COPY_TO_PERSONAL_EMAIL: "Enviar copia para seu Email pessoal",
      ACTIVITY_RELATED_EMAILS: "Email relacionado a atividade",
      WHEN_TO_EMAIL: "Quando enviar Email",
      YOU_HAVE_NEW_NOTIFICATIONS: "Você tiver notificações",
      YOU_ARE_SENT_A_DIRECT_MESSAGE: "Você enviou uma mensagem direta",
      SOMEONE_ADDS_YOU_AS_A_CONNECTION: "Alguém lhe adicionou como uma conexão",
      WHEN_TO_ESCALATE_EMAIL: "Quando escalar emails",
      UPON_NEW_ORDER: "Por emails novos",
      NEW_MEMBERSHIP_APPROVAL: "Aprovação de um novo membro",
      MEMBER_REGISTRATION: "Registro de membro",
      UPDATES_FROM_KEENTHEMES: "Atualizações de keenthemes",
      EMAIL_YOU_WITH: "Enviar email com",
      NEWS_ABOUT_KEENTHEMES_PRODUCTS_AND_FEATURE_UPDATES:
        "Notícias sobre produtos Keenthemes e atualizações de recursos",
      TIPS_ON_GETTING_MORE_OUT_OF_KEEN:
        "Dicas para tirar mais proveito do Keen",
      THINGS_YOU_MISSED_SINCE_YOU_LAST_LOGGED_INTO_KEEN:
        "Coisas que você perdeu desde o último login no Keen",
      NEWS_ABOUT_METRONIC_ON_PARTNER_PRODUCTS_AND_OTHER_SERVICES:
        "Notícias sobre a metronic em produtos de parceiros e outros serviços",
      TIPS_ON_METRONIC_BUSINESS_PRODUCTS:
        "Dicas sobre produtos empresariais Metronic",
      CREDIT_CARD: "Cartão de crédito salvo",
      USER_ADDRESS: "Endereço do Usuário",
      NEW_ADDRESS: "Novo endereço",
      ADDRESS_1: "Endereço 1",
      PRIMARY: "Primário",
      STREET: "Rua",
      NUMBER: "Número",
      COMPLEMENT: "Complemento",
      NEIGHBORHOOD: "Bairro",
      POSTAL_CODE: "Código Postal",
      SELECT_STATE: "Selecione o estado",
      SELECT_STATE_TIP: "Por favor selecione o estado",
      SELECT_CITY: "Selecione a cidade",
      SELECT_CITY_TIP: "Por favor selecione a cidade",
      USER_PROFILE: "Perfil de Usuário",
    },
    LANGUAGES: {
      PORTUGUESE: "Português",
      ENGLISH: "Inglês",
      SPANISH: "Espanhol",
      GERMAN: "Alemão",
      FRENCH: "Francês",
      MANDARIN: "Mandarim",
      JAPANESE: "Japonês",
    },
    TUTORIAL_MANAGEMENT: {
      TUTORIAL_CATEGORIES: "Categorias",
      TUTORIAL: "Tutorial",
      NAME: "Nome",
      LAST_UPDATE: "Última atualização",
      CREATE_DATE: "Data de criação",
      DESCRIPTION: "Descrição",
      ACTIVE: "Ativo",
      INACTIVE: "Inativo",
      CATEGORIES: {
        NEW_CATEGORY: "Nova categoria",
        DESCRIPTION: "Descrição",
        EDIT_CATEGORY: "Editar categoria",
        CATEGORY: "Categoria",
        DELETE_CATEGORY: "Deletar categoria",
        CATEGORY_IS_BEING_DELETED: "Categoria está sendo excluída.",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_CATEGORY:
          "Tem certeza de que deseja excluir permanentemente esta categoria?",
      },
      TUTORIALS: {
        TUTORIALS: "Tutoriais",
        CREATE_TUTORIAL: "Criar tutorial",
        DELETE_TUTORIAL: "Deletar tutorial",
        EDIT_TUTORIAL: "Editar tutorial",
        MEDIA_LINK: "Link de mídia",
        IMAGE_LINK: "Link de imagem",
        TUTORIAL_IS_BEING_DELETED: "Tutorial está sendo excluído.",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_TUTORIAL:
          "Tem certeza de que deseja excluir permanentemente este tutorial?",
      },
    },

    EXPORT_MANAGEMENT: {
      NAME: "Nome",
      STATUS: "Status",
      EXPORTED_DATE: "Data de exportação",
      CREATE_DATE: "Data de criação",
      MODIFIED_DATE: "Data de modificação",
      IS_OUTDATED: "Está desatualizado",
      MAX_AREA_ALLOWED: "Área máxima permitida",
      MIN_AREA_ALLOWED: "Área mínima permitida",
      MEDIA_FILE_URL: "Url",
      MESSAGE: "Mensagem",
      DESCRIPTION: "Descrição",
      ENABLED: "Está habilitado?",
      YES: "Sim",
      NO: "Não",
      ERROR_AREA_MAX: "A área máxima deve ser maior do que a minima",
      ERROR_NAME: "O nome é obrigatório.",
      CONFIGURATION: "Configurações",
      LAYERS: "Camadas",
      PRICE_PER_HECTARE: "Preço por hectare",
      LAYER: "Layer",
      RATIO: "Proporção",
      LVC_SHORT: "Versão",
      TRIM_CONFIGURATION: {
        TRIM_CONFIGURATION: "Configurações do Exporter",
        CREATE_TRIM_CONFIGURATION: "Criar Exporter",
        ADD_TRIM_CONFIGURATION: "Adicionar configuração de Exporter",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_TRIM_CONFIGURATION:
          "Tem certeza de que deseja excluir permanentemente esta configuração de Exporter?",
        TRIM_CONFIGURATION_IS_BEING_DELETED:
          "A configuração de Exporter está sendo excluída...",
        DELETE_TRIM_CONFIGURATION: "Deletar configuração de Exporter",
        EDIT_TRIM_CONFIGURATION: "Editar configuração de Exporter",
      },
      TRIM_LAYERS: {
        SYNC_MAP_LAYERS: "Sincronizar Camadas do Mapa",
        TRIM_LAYERS: "Camadas do trim",
        EXPORTER_LAYERS: "Camadas do Exporter",
        CREATE_TRIM_LAYER: "Criar camada do Exporter",
        DELETE_TRIM_LAYER: "Deletar camada do Exporter",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_TRIM_LAYER:
          "Tem certeza de que deseja excluir permanentemente esta layer do Exporter?",
        EDIT_TRIM_LAYER: "Editar camada do Exporter",
        TRIM_LAYER_IS_BEING_DELETED:
          "Camada do Exporter está sendo excluída...",
        PRICE_MIN_ERROR: "O preço não pode ser negativo",
        EDIT_TRIM_LAYERS: "Editar camadas selecionadas",
        SELECTED_TRIM_LAYERS_COUNT: "Camadas selecionadas:",
        TRIM_CONFIGURATION_UPDATE_FOR_SELECTED_LAYERS:
          "Atualização de configuração do Exporter para as camadas seleciondas",
        DELETE_TRIM_LAYERS: {
          TRIM_LAYERS_DELETE: "Deletar camadas do Exporter",
          ARE_YOU_SURE_TO_DELETE_SELECTED_TRIM_LAYERS:
            "Você tem certeza que deseja deletar as camadas do Exporter selecionadas?",
          TRIM_LAYERS_ARE_BEING_DELETED:
            "As camadas do Exporter estão sendo deletadas.",
        },
      },
      TRIM_RATIO: {
        TRIM_RATIO: "Proporções do Exporter",
        MULTIPLIER: "Multiplicador",
        CREATE_TRIM_RATIO: "Criar proporção do Exporter",
        DELETE_TRIM_RATIO: "Deletar proporção do Exporter",
        EDIT_TRIM_RATIO: "Editar proporção do Exporter",
        USER_ROLE: "Papel do usuário",
        TRIM_RATIO_IS_BEING_DELETED:
          "Proporção do Exporter está sendo excluída...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_TRIM_RATIO:
          "Tem certeza de que deseja excluir permanentemente esta proporção do Exporter?",
        ERROR_MIN_TRIM_MULTIPLIER: "O multiplicador deve ser positivo",
      },
    },
    SUBSCRIPTIONS_MANAGEMENT: {
      CREATE_NEW_SUBSCRIPTION: "Criar nova assinatura",
      CREATE_NEW_SUBSCRIPTIONS: {
        NEW_CATEGORY: "Nova categoria",
      },
    },
    PAGINATOR: {
      LOADING: "Carregando...",
      SHOWING: "Mostrando linhas",
      TO: "até",
      OF: "de",
    },
    USER_DROPDOWN: {
      NEW_GROUP: "Novo grupo",
      CONTACTS: "Contatos",
      GROUPS: "Grupos",
      CALLS: "Chamadas",
      SETTINGS: "Configurações",
      HELP: "Ajuda",
      PRIVACY: "Privacidade",
    },
    USER_MANAGEMENT: {
      USER_COMPANY: {
        SELECT_ORGANIZATION: "Selecione uma organização",
        EDIT_USER: "Editar usuário",
        CNPJ: "CNPJ",
        ROLE: "Cargo",
        STATUS: "Status",
        ADMIN: "Administrador",
        MEMBER: "Membro",
        MEMBERS: "Membros",
        INVITE_USER: "Convidar Usuário",
        INVITE_EMAIL: "Email convidado",
        USERS: "Usuários",
        ORGANIZATION: "Organização",
        NEW_ORGANIZATION: "Nova Organização",
        INVITES_AVAILABLE: "Convites disponíveis",
        LIST: "Lista de Organizações",
        COMPANY: "Empresa",
        CORPORATE_NAME: "Razão social",
        TRADING_NAME: "Nome fantasia",
        TELEPHONE: "Telefone",
        CELLPHONE: "Celular",
        CONTACT_NAME: "Responsável",
        CONTACT_EMAIL: "E-mail contato",
        FINANCIAL_EMAIL: "E-mail financeiro",
        FINANCIAL_OFFICER: "Responsável financeiro",
        ADDITIONAL_INFORMATION: "Informaçôes adicionais",
        DELETE: "Deletar",
        IS_DELETING: "Deletando",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE:
          "Tem a certeza de que pretende deletar permanentemente está empresa?\nTodos os usuários vinculados perderão o vínculo com esta empresa",
        ORG_NAME: "Nome da organização",
        OWNER_NAME: "Proprietário da organização",
        USER_ID: "ID do User",
        SEGMENTS: "Segmentos",
      },
      EDIT_ROLE: {
        ROLE: "Role",
      },
      USERS: {
        USERS_LIST: "Lista de Usuários",
        NEW_USER: "Novo Usuário",
      },
      EDIT_USER: {
        USER: "Usuário",
        GENDER_FEMALE: "Feminino",
        GENDER_MALE: "Masculino",
        ORGANIZATIONS: "Organizações",
        BIRTHDAY: "Data de Nascimento",
        GENDER: "Gênero",
        ABOUT: "Sobre",
        PHONE_NUMBER: "Telefone",
        SITE: "Site",
        COMPANY_NAME: "Nome da Empresa",
        CPF_CNPJ: "CPF/CNPJ",
        REALM_ROLES: "Acessos",
        FIRST_NAME: "Primeiro Nome",
        LAST_NAME: "Sobrenome",
        EDIT: "Editar",
        CREATE: "Criar",
        BACK: "Voltar",
        RESET: "Resetar",
        BASIC_INFO: "Informações Básicas",
        PROFILE_INFO: "Informações do Perfil",
        ADDRESS_INFO: "Informações de Endereço",
        ENTER_USERNAME: "Entre com o Nome de Usuário/E-mail",
        ENTER_USERNAME_TIP: "Por favor, digite o nome de Usuário/E-mail",
        USERNAME_WAS_ENTERED_CORRECT:
          "Nome de Usuário foi adicionado corretamente",
        USERNAME_IS_REQUIRED: "Nome de Usuário é obrigatório",
        USERNAME_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
          "Nome de Usuário deve ter no minimo 3 símbolos",
        USERNAME_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
          "Nome de Usuário deve ter no máximo 100 símbolos",
        ENTER_CPF_CNPJ: "Entre com CPF/CNPJ",
        ENTER_CPF_CNPJ_TIP: "Por favor, digite o CPF/CNPJ",
        CPF_CNPJ_WAS_ENTERED_CORRECT: "CPF/CNPJ foi adicionado corretamente",
        CPF_CNPJ_IS_REQUIRED: "CPF/CNPJ é obrigatório",
        SELECT_PLAN: "Selecione um plano",
        SELECT_PLAN_TIP: "Por favor, selecione um plano",
        SELECT_ACTIVE: "Selecione ativo",
        ACTIVE: "Ativo",
        INACTIVE: "Inativo",
        SELECT_ACTIVE_TIP: "Por favor selecione ativo",
        SELECT_ENABLED: "Selecione ativado",
        ENABLED: "Ativado",
        DISABLED: "Desativado",
        SELECT_ENABLED_TIP: "Por favor selecione ativado",
        SELECT_CREDENTIALS_EXPIRED: "Selecione as credenciais expiradas",
        SELECT_CREDENTIALS_EXPIRED_TIP:
          "Porfavor selecione as credenciais expiradas",
        SELECT_EXPIRED: "Selecione expirado",
        SELECT_EXPIRED_TIP: "Porfavor selecione expirado",
        SELECT_LOCKED: "Selecione trancado",
        SELECT_LOCKED_TIP: "Porfavor selecione trancado",
        CANCEL: "Cancelar",
        SAVE: "Salvar",
        RESTRICTION: "Restrições",
        RESTRICTION_LIST: "Lista de Restrições",
        RESTRICTION_NAME: "Nome da restrição",
        MAX_USAGE: "Uso máximo",
        USER_RESTRICTION_EDIT: "Editar restrição do usúario",
      },
      USER_PROFILE_EDIT: {
        DATE_OF_BIRTH: "Data de nascimento",
        DATE_OF_BIRTH_WAS_ENTERED_CORRECT:
          "Data de nascimento foi adicionado corretamente",
        DATE_OF_BIRTH_TIP:
          'Porfavor entre com a data de nascimento no formato "dd/mm/yyyy"',
        SELECT_GENDER: "Selecione o gênero",
        FEMALE: "Feminino",
        MALE: "Masculino",
        SELECT_GENDER_TIP: "Porfavor selecione o gênero",
        ENTER_REAL_NAME: "Entre com nome real",
        ENTER_REAL_NAME_TIP: "Porfavor entre com o nome real",
        ENTER_REAL_NAME_WAS_ENTERED_CORRECT:
          "Nome real foi adicionado corretamente",
        ENTER_REAL_NAME_IS_REQUIRED: "Nome real é obrigatório",
        ENTER_REAL_NAME_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
          "Nome real deve ter no minimo 3 símbolos",
        ENTER_REAL_NAME_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
          "Nome real deve ter no máximo 100 símbolos",
        ENTER_EMAIL: "Entre com Email",
        ENTER_EMAIL_TIP: "Porfavor entre com o Email",
        ENTER_EMAIL_WAS_ENTERED_CORRECT: "Email foi adicionado corretamente",
        ENTER_EMAIL_IS_REQUIRED: "Email é obrigatório",
        ENTER_EMAIL_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
          "Email deve ter no minimo 3 símbolos",
        ENTER_EMAIL_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
          "Email deve ter no máximo 100 símbolos",
        ENTER_PHONE_NUMBER: "Entre com o número de telefone",
        ENTER_PHONE_NUMBER_TIP: "Porfavor entre com o número de telefone",
        ENTER_PHONE_NUMBER_WAS_ENTERED_CORRECT:
          "número de telefone foi adicionado corretamente",
        ENTER_PHONE_NUMBER_IS_REQUIRED: "número de telefone é obrigatório",
        ENTER_PHONE_NUMBER_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
          "número de telefone deve ter no minimo 3 símbolos",
        ENTER_PHONE_NUMBER_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
          "número de telefone deve ter no máximo 100 símbolos",
        ENTER_SITE: "Entre com site",
        ENTER_SITE_TIP: "Porfavor entre com o site",
        ENTER_SITE_WAS_ENTERED_CORRECT: "Site foi adicionado corretamente",
        ENTER_SITE_IS_REQUIRED: "Site é obrigatório",
        ENTER_SITE_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
          "Site deve ter no minimo 3 símbolos",
        ENTER_SITE_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
          "Site deve ter no máximo 100 símbolos",
        CANCEL: "Cancelar",
        SAVE: "Salvar",
      },
      DELETE_USER: {
        USER_DELETE: "Excluir Usuário",
        USER_IS_DELETING: "O Usuário está excluindo...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_USER:
          "Tem certeza de que deseja excluir permanentemente este Usuário?",
        CANCEL: "Cancelar",
        DELETE: "Deletar",
      },
      DELETE_USERS: {
        USERS_DELETE: "Excluir Usuários",
        USERS_IS_DELETING: "Os Usuários estão sendo excluindo...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_USERS:
          "Tem certeza de que deseja excluir permanentemente os Usuários selecionados?",
        CANCEL: "Cancel",
        DELETE: "Deletar",
      },
      FETCH_USERS: {
        FETCH_SELECTED_USERS: "Buscar Usuários selecionados",
        USERNAME: "Nome de Usuário",
        STATUS: "Status",
        SUSPENDED: "Suspenso",
        ACTIVE: "Ativo",
        INACTIVE: "Inativo",
        PENDING: "Pendente",
        CANCEL: "Cancelar",
        FETCH: "Buscar",
      },
      UPDATE_USERS: {
        STATUS_UPDATE_FOR_SELECTED_USERS:
          "Atualização de status para Usuários selecionados",
        USERNAME: "Nome de Usuário",
        STATUS: "Status",
        SUSPENDED: "Suspenso",
        ACTIVE: "Ativo",
        INACTIVE: "Inativo",
        PENDING: "Pendente",
        CANCEL: "Cancelar",
        UPDATE_STATUS: "Atualizar o status",
      },
      ROLES: {
        ROLE: "Papel",
        ROLES_COMPOSITE: "Composição da role",
        ROLES_LIST: "Lista de papeis",
      },
      DELETE_ROLE: {
        ROLE_DELETE: "Excluir papel",
        ROLE_IS_DELETING: "O papel está sendo excluído...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_ROLE:
          "Tem certeza de que deseja excluir permanentemente este papel?",
      },
      DELETE_ROLES: {
        ROLES_DELETE: "Excluir papeis",
        ROLES_IS_DELETING: "Os papeis estão sendo excluídos...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_ROLES:
          "Tem certeza de que deseja excluir permanentemente os papeis selecionados?",
      },
      FETCH_ROLES: {
        FETCH_SELECTED_ROLES: "Buscar os papeis selecionados",
      },
      UPDATE_ROLES: {
        STATUS_UPDATE_FOR_SELECTED_ROLES:
          "Atualização de status para os papeis selecionados",
      },
      SITES: {
        SITES_LIST: "Lista de sites",
        NEW_SITE: "Novo site",
        PRIMARY_COLOR: "Cor primária",
        SECONDARY_COLOR: "Cor secundária",
        ACCENT_COLOR: "Cor de destaque",
      },
      DELETE_SITE: {
        SITE_DELETE: "Excluir site",
        SITE_IS_DELETING: "O site está sendo excluído...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_SITE:
          "Tem certeza de que deseja excluir permanentemente este site?",
      },
      DELETE_SITES: {
        SITES_DELETE: "Excluir sites",
        SITES_IS_DELETING: "Os sites estão sendo excluídos...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_SITES:
          "Tem certeza de que deseja excluir permanentemente os sites selecionados?",
      },
      FETCH_SITES: {
        FETCH_SELECTED_SITES: "Buscar os sites selecionados",
      },
      UPDATE_SITES: {
        STATUS_UPDATE_FOR_SELECTED_SITES:
          "Atualização de status para os sites selecionados",
      },
      LEVELS: {
        LEVEL: "Nível",
        LEVELS_LIST: "Lista de níveis",
      },
      EDIT_LEVEL: {
        SELECT_VIEW_MENU: "Selecione o menu de visualização",
        VIEW_MENU: " ver menu",
        SELECT_VIEW_ASSETS: "Selecione visualizar ativos",
        VIEW_ASSETS: " ver ativos",
        SELECT_VIEW_NOTIFICATIONS: "Selecione ver notificações",
        VIEW_NOTIFICATIONS: " ver notificações",
        SELECT_VIEW_CHATS: "Selecione  ver chats",
        VIEW_CHATS: " ver chats",
        SELECT_VIEW_OPPORTUNITIES: "Selecione ver oportunidades",
        VIEW_OPPORTUNITIES: " ver oportunidades",
        SELECT_VIEW_ACTIVE_INFOMAPS: "Selecione ver mapas de informação ativos",
        VIEW_ACTIVE_INFOMAPS: " ver mapas de informação ativos",
        SELECT_VIEW_MAPBOX: "Selecione  ver caixa de mapa",
        VIEW_MAPBOX: " ver caixa de mapa",
        SELECT_VIEW_EBI: "Selecione ver e-BI ",
        VIEW_EBI: " ver e-BI",
        SELECT_VIEW_ECOMAP: "Selecione ver mapa ecológico",
        VIEW_ECOMAP: " ver mapa ecológico",
        SELECT_VIEW_ECOBOARD: "Selecione ver quadro ecológico",
        VIEW_ECOBOARD: " ver quadro ecológico",
        SELECT_VIEW_ECOFLOW: "Selecione a visualização do fluxo ecológico",
        VIEW_ECOFLOW: " ver fluxo ecológico",
        PLEASE_SELECT: "Por favor selecione",
      },
      DELETE_LEVEL: {
        LEVEL_DELETE: "Excluir nível",
        LEVEL_IS_DELETING: "O nível está sendo excluído...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_LEVEL:
          "Tem certeza de que deseja excluir permanentemente este nível?",
      },
      DELETE_LEVELS: {
        LEVELS_DELETE: "Excluir níveis",
        LEVELS_IS_DELETING: "Os níveis estão sendo excluídos...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_LEVELS:
          "Tem certeza de que deseja excluir permanentemente os níveis selecionados?",
      },
      FETCH_LEVELS: {
        FETCH_SELECTED_LEVELS: "Buscar os níveis selecionados",
      },
      UPDATE_LEVELS: {
        STATUS_UPDATE_FOR_SELECTED_LEVELS:
          "Atualização de status para os níveis selecionados",
      },
      PLANS: {
        PLANS_LIST: "Lista de planos",
        PRICE: "Preço",
      },
      EDIT_PLAN: {
        PLAN: "plano",
        PRICE: "Preço",
        REFERENCE_CODE: "Código de referência",
        ACCESS_DURATION: "Duração de acesso",
      },
      DELETE_PLAN: {
        PLAN_DELETE: "Excluir plano",
        PLAN_IS_DELETING: "O plano está sendo excluído...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_PLAN:
          "Tem certeza de que deseja excluir permanentemente este plano?",
      },
      DELETE_PLANS: {
        PLANS_DELETE: "Excluir planos",
        PLANS_IS_DELETING: "Os planos estão sendo excluídos...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_PLANS:
          "Tem certeza de que deseja excluir permanentemente os planos selecionados?",
      },
      FETCH_PLANS: {
        FETCH_SELECTED_PLANS: "Buscar os planos selecionados",
      },
      UPDATE_PLANS: {
        STATUS_UPDATE_FOR_SELECTED_PLANS:
          "Atualização de status para os planos selecionados",
      },
      USERS_MAP: {
        USER_MAP: "Mapa do Usuário",
        USERS_MAP_LIST: "Lista de mapas de Usuários",
        USER_LEVEL: "Nível do Usuário",
        USER_MAP_2D: "Mapa do Usuário 2D",
        USER_MAP_3D: "Mapa do Usuário 3D",
      },
      EDIT_USER_MAP: {
        USER: "Usuário",
        SELECT_USER: "Selecione o Usuário",
        PLEASE_SELECT: "Por favor selecione",
        SELECT_USER_LEVEL: "Selecione o nível do Usuário",
        USER_LEVEL: "nível do Usuário",
        SELECT_USER_MAP_2D: "Selecione o mapa do Usuário 2D",
        SELECT_USER_MAP_3D: "Selecione o mapa do Usuário 3D",
        USER_MAP_2D: "mapa do Usuário 2D",
        USER_MAP_3D: "mapa do Usuário 3D",
      },
      DELETE_USER_MAP: {
        USER_MAP_DELETE: "Excluir mapa do Usuário",
        USER_MAP_IS_DELETING: "O mapa do Usuário está sendo excluído...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_USER_MAP:
          "Tem certeza de que deseja excluir permanentemente este mapa do Usuário?",
      },
      DELETE_USERS_MAP: {
        USERS_MAP_DELETE: "Excluir mapa dos Usuários",
        USERS_MAP_IS_DELETING: "O mapa dos Usuários estão sendo excluídos...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_USERS_MAP:
          "Tem certeza de que deseja excluir permanentemente o mapa dos Usuários selecionados?",
      },
      FETCH_USERS_MAP: {
        FETCH_SELECTED_USERS_MAP: "Buscar mapa dos Usuários selecionados",
      },
      UPDATE_USERS_MAP: {
        STATUS_UPDATE_FOR_SELECTED_USERS_MAP:
          "Atualização de status para o mapa dos Usuários selecionados",
      },
      LOGINS: {
        LOGINS_LIST: "Lista de logins",
        CREATE_DATE: "Data de criação",
        IP_ADDRESS: "Endereço de IP",
      },
      USERS_EBI: {
        USERS_EBI_LIST: "Lista de e-BI",
        NEW_USER_EBI: "Novo e-BI",
        USER_LEVEL: "Nível do Usuário",
        EBI_TYPE: "Tipo de e-BI",
        ORDER: "Ordem",
      },
      EDIT_USER_EBI: {
        USER_EBI: "e-BI",
        SELECT_USER: "Selecione o Usuário",
        PLEASE_SELECT: "Por favor selecione",
        SELECT_USER_LEVEL: "Selecione o nível do Usuário",
        USER_LEVEL: " nível do Usuário",
        SELECT_EBI_TYPE: "Selecione o Tipo de e-BI",
        EBI_TYPE: " Tipo de e-BI ",
        EBI_URL: "URL do e-BI",
        ORDER: "Ordem",
        THUMBNAIL: "Miniatura",
      },
      DELETE_USER_EBI: {
        USER_EBI_DELETE: "Excluir e-BI",
        USER_EBI_IS_DELETING: "O e-BI está sendo excluído...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_USER_EBI:
          "Tem certeza de que deseja excluir permanentemente este e-BI?",
      },
      DELETE_USERS_EBI: {
        USERS_EBI_DELETE: "Excluir e-BIs",
        USERS_EBI_IS_DELETING: "Os e-BIs estão sendo excluídos...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_USERS_EBI:
          "Tem certeza de que deseja excluir permanentemente os e-BIs selecionados?",
      },
      FETCH_USERS_EBI: {
        FETCH_SELECTED_USERS_EBI: "Buscar os e-BIs selecionados",
      },
      UPDATE_USERS_EBI: {
        STATUS_UPDATE_FOR_SELECTED_USERS_EBI:
          "Atualização de status para os e-BIs selecionados",
      },
      USER_ACCESS_MANAGEMENT: {
        USER_ACCESS_MANAGEMENT_LIST:
          "Lista de gerenciamento de acesso do Usuário",
        NEW_USER_ACCESS_MANAGEMENT: "Gerenciamento de acesso de novo Usuário",
        USER_EMAIL_USERNAME: "E-mail do Usuário/ Nome do Usuário",
        USER_REALNAME: "Nome real do Usuário",
        COMPANY_NAME: "Nome da empresa",
        CREDENTIALS_EXPIRED: "Credenciais expiradas",
        ACCESS_EXP_DATE: "Data de exp. do acesso",
      },
      EDIT_USER_ACCESS_MANAGEMENT: {
        USER_ACCESS_MANAGEMENT: "gestão de acesso do Usuário",
        USER_EMAIL_USERNAME: "E-mail do Usuário/ Nome do Usuário",
        USER_REALNAME: "Nome real do Usuário",
        COMPANY_NAME: "Nome da empresa",
        SELECT_ENABLED: "Selecione habilitado",
        PLEASE_SELECT: "Por favor selecione",
        SELECT_CREDENTIALS_EXPIRED: "Selecione credenciais expiradas",
        CREDENTIALS_EXPIRED: "credenciais expiradas",
        SELECT_EXPIRED: "Selecione expirado",
        SELECT_LOCKED: "Selecione bloqueado",
        ACCESS_EXP_DATE: "Data de expiração do acesso",
        DD_MM_YYYY: "DD/MM/AAAA",
        PLEASE_ENTER: "Por favor, insira",
        IN_DD_MM_YYYY_FORMAT: "no formato DD/MM/AAAA",
      },
      DELETE_USER_ACCESS_MANAGEMENT: {
        USER_ACCESS_MANAGEMENT_DELETE: "Excluir gestão de acesso do Usuário",
        USER_ACCESS_MANAGEMENT_IS_DELETING:
          "A gestão de acesso do Usuário está sendo excluído...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_USER_ACCESS_MANAGEMENT:
          "Tem certeza de que deseja excluir permanentemente está gestão de acesso do Usuário?",
      },
      DELETE_USERS_ACCESS_MANAGEMENT: {
        USERS_ACCESS_MANAGEMENT_DELETE: "Excluir gestão de acesso do Usuários",
        USERS_ACCESS_MANAGEMENT_IS_DELETING:
          "As gestões de acesso dos Usuários estão sendo excluidas ...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_USERS_ACCESS_MANAGEMENT:
          "Tem certeza de que deseja excluir permanentemente as gestões de acesso dos Usuários selecionados?",
      },
      FETCH_USERS_ACCESS_MANAGEMENT: {
        FETCH_SELECTED_USERS_ACCESS_MANAGEMENT:
          "Buscar gestão de acesso dos Usuários",
      },
      UPDATE_USERS_ACCESS_MANAGEMENT: {
        STATUS_UPDATE_FOR_SELECTED_USERS_ACCESS_MANAGEMENT:
          "Atualização de status para gestão de acesso do Usuário selecionados",
      },
      COMPANIES: {
        COMPANY: "Empresa",
        COMPANIES_LIST: "Lista de Organizações",
        CNPJ: "CNPJ",
        EMAIL: "Email",
        PHONE_NUMBER: "Número de telefone",
      },
      EDIT_COMPANY: {
        CNPJ: "CNPJ",
        EMAIL: "Email",
        PHONE_NUMBER: "Número de telefone",
      },
      DELETE_COMPANY: {
        COMPANY_DELETE: "Excluir empresa",
        COMPANY_IS_DELETING: "A empresa está sendo excluído...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_COMPANY:
          "Tem certeza de que deseja excluir permanentemente está empresa?",
      },
      DELETE_COMPANIES: {
        COMPANIES_DELETE: "Excluir empresas",
        COMPANIES_IS_DELETING: "As empresas estão sendo excluidas ...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_COMPANIES:
          "Tem certeza de que deseja excluir permanentemente as empresas selecionados?",
      },
      FETCH_COMPANIES: {
        FETCH_SELECTED_COMPANIES: "Buscar as empresas selecionadas",
      },
      UPDATE_COMPANIES: {
        STATUS_UPDATE_FOR_SELECTED_COMPANIES:
          "Atualização de status para empresas selecionadas",
      },
      USERS_ADDRESS: {
        USER_ADDRESS: "Endereço do Usuário",
        USERS_ADDRESS_LIST: "Lista de Endereços de Usuários",
        STREET: "Rua",
        NUMBER: "Número",
        COMPLEMENT: "Complemento",
        NEIGHBORHOOD: "Bairro",
        POSTAL_CODE: "Código postal",
        STATE: "Estado",
        CITY: "Cidade",
      },
      EDIT_USER_ADDRESS: {
        SELECT_USER: "Selecione o Usuário",
        PLEASE_SELECT: "Por favor selecione",
        STREET: "Rua",
        NUMBER: "Número",
        COMPLEMENT: "Complemento",
        NEIGHBORHOOD: "Bairro",
        POSTAL_CODE: "Código postal",
        SELECT_STATE: "Selecione o estado",
        STATE: "Estado",
        SELECT_CITY: "Selecione a cidade",
        CITY: "Cidade",
        USER_ADDRESS: "endereço",
      },
      DELETE_USER_ADDRESS: {
        USER_ADDRESS_DELETE: "Excluir endereço do Usuário",
        USER_ADDRESS_IS_DELETING:
          "O endereço do Usuário está sendo excluído...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_USER_ADDRESS:
          "Tem certeza de que deseja excluir permanentemente este endereço de Usuário?",
      },
      DELETE_USERS_ADDRESS: {
        USERS_ADDRESS_DELETE: "Excluir endereço dos Usuários",
        USERS_ADDRESS_IS_DELETING:
          "Os endereços dos Usuários estão sendo excluídos...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_USERS_ADDRESS:
          "Tem certeza de que deseja excluir permanentemente o endereço dos Usuários selecionados?",
      },
      FETCH_USERS_ADDRESS: {
        FETCH_SELECTED_USERS_ADDRESS:
          "Buscar o endereço dos Usuários selecionados",
      },
      UPDATE_USERS_ADDRESS: {
        STATUS_UPDATE_FOR_SELECTED_USERS_ADDRESS:
          "Atualização de status para endereços de Usuários selecionados",
      },
      USERS_EBI_TYPE: {
        USERS_EBI_TYPE_LIST: "Lista do Tipo de e-BI",
        NEW_USER_EBI_TYPE: "Novo Tipo de e-BI",
      },
      EDIT_USER_EBI_TYPE: {
        USER_EBI_TYPE: "Tipo de e-BI",
        ORDER: "Ordem",
      },
      DELETE_USER_EBI_TYPE: {
        USER_EBI_TYPE_DELETE: "Excluir Tipo de e-BI",
        USER_EBI_TYPE_IS_DELETING: "O Tipo de e-BI está sendo excluído...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_USER_EBI_TYPE:
          "Tem certeza de que deseja excluir permanentemente este Tipo de e-BI?",
      },
      DELETE_USERS_EBI_TYPE: {
        USERS_EBI_TYPE_DELETE: "Excluir Tipos do e-BI",
        USERS_EBI_TYPE_IS_DELETING: "Os Tipos do e-BI estão sendo excluídos...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_USERS_EBI_TYPE:
          "Tem certeza de que deseja excluir permanentemente os Tipos do e-BI selecionados?",
      },
      FETCH_USERS_EBI_TYPE: {
        FETCH_SELECTED_USERS_EBI_TYPE: "Buscar os Tipos do e-BI selecionados",
      },
      UPDATE_USERS_EBI_TYPE: {
        STATUS_UPDATE_FOR_SELECTED_USERS_EBI_TYPE:
          "Atualização de status para os Tipos do e-BI selecionados",
      },
      FEED: {
        FEEDS_LIST: "Lista de Feeds",
        FEED: "Feed",
        FEED_TYPE: "Tipo de Feed",
        ACTIVITY: "Atividade",
        BLOG: "Blog",
        CALENDAR: "Calendário",
        CREATE_DATE: "Data de criação",
        LAST_UPDATE: "Última atualização",
      },
      EDIT_FEED: {
        DESCRIPTION: "Descrição",
        FEED_TYPE: "Tipo de Feed",
        COLOR: "Cor",
        CATEGORY: "Categoria",
        TEXT: "Texto",
        LINK: "Link",
        TITLE: "Título",
        MEDIA_LINK: "Link de mídia",
        EVENT_DATE: "Data do evento",
      },
      DELETE_FEED: {
        FEED_DELETE: "Excluir Feed",
        FEED_IS_DELETING: "O Feed está sendo excluído...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_FEED:
          "Tem certeza de que deseja excluir permanentemente este Feed?",
      },
      DELETE_FEEDS: {
        FEEDS_DELETE: "Excluir Feeds",
        FEEDS_IS_DELETING: "Os Feeds estão sendo excluídos...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_FEEDS:
          "Tem certeza de que deseja excluir permanentemente os Feeds selecionados?",
      },
      FETCH_FEED: {
        FETCH_SELECTED_FEEDS: "Buscar os Feeds selecionados",
      },
      UPDATE_FEED: {
        STATUS_UPDATE_FOR_SELECTED_FEEDS:
          "Atualização de status para Feeds selecionados",
      },
      RESTRICTIONS_TEMPLATE: {
        RESTRICTIONS_TEMPLATE: "Modelo de Restrição",
        RESTRICTIONS_TEMPLATE_LIST: "Lista de Modelos de Restrição",
        SUBSCRIPTION_NAME: "Nome da Assinatura",
        TEMPLATE_NAME: "Nome do Modelo ",
      },
      EDIT_RESTRICTIONS_TEMPLATE: {
        RESTRICTIONS_TEMPLATE: "Modelo de restrições",
        TEMPLATE_NAME: "Nome do modelo",
        PRODUCT: "Produto",
        MAX_STORAGE_SPACE: "Espaço máximo de armazenamento (MB)",
        MAX_WORKSPACES: "Máximo de espaços de trabalho",
        MAX_LAYERS: "Camadas máximas",
        MAX_GEOMETRIES_PER_LAYER: "Geometrias máximas por camada",
        MAX_INFO_DATLAZ_REQUESTS: "Solicitações máximas no InfoDatlaz",
        MAX_SENTINEL_REQUESTS: "Máximo de solicitações do Sentinel-2",
      },
      DELETE_RESTRICTIONS_TEMPLATE: {
        RESTRICTIONS_TEMPLATE_DELETE: "Excluir Modelo de Restrições",
        RESTRICTIONS_TEMPLATE_IS_DELETING:
          "O Modelo de Restrições está sendo excluído...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_RESTRICTIONS_TEMPLATE:
          "Tem certeza de que deseja excluir permanentemente este Modelo de Restrições?",
      },
      DELETE_RESTRICTIONS_TEMPLATES: {
        RESTRICTIONS_TEMPLATE_DELETE: "Excluir Modelos de Restrições",
        RESTRICTIONS_TEMPLATE_IS_DELETING:
          "Os Modelos de Restrições estão sendo excluídos...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_RESTRICTIONS_TEMPLATE:
          "Tem certeza de que deseja excluir permanentemente os Modelos de Restrições selecionados?",
      },
      FETCH_RESTRICTIONS_TEMPLATE: {
        FETCH_SELECTED_RESTRICTIONS_TEMPLATE:
          "Buscar os Modelos de Restrições selecionados",
      },
      UPDATE_RESTRICTIONS_TEMPLATE: {
        STATUS_UPDATE_FOR_SELECTED_RESTRICTIONS_TEMPLATE:
          "Atualização de status para os Modelos de Restrições selecionados",
      },
    },
    MAP_DATA_MANAGEMENT: {
      MAP_CATEGORY_LAYERS: {
        ACTIVE_ON_INIT: "Ativada ao iniciar",
        ACTIVATE_ON_INIT: "Ativar ao iniciar",
        DEACTIVATE_ON_INIT: "Desativar ao iniciar",
        SELECT_CATEGORY: "Selecionar Categoria",
        MOVE_CATEGORY: "Mover para outra Categoria",
        MOVING: "Movendo",
        MOVE: "Mover",
      },
      DELETE_CATEGORIES: {
        CATEGORIES_DELETE: "Deletar Categorias",
        CATEGORIES_IS_DELETING: "As Categorias estão sendo deletadas...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_CATEGORIES:
          "Você tem certeza que deseja deletar essas Categorias?",
      },
      DELETE_MAP_CATEGORY: {
        MAP_CATEGORY_DELETE: "Deletar Categoria",
        MAP_CATEGORY_IS_DELETING: "A Categoria está sendo deletada...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_MAP_CATEGORY:
          "Você tem certeza que deseja deletar essa Categoria?",
      },
      DELETE_MAP: {
        MAP_DELETE: "Deletar mapa",
        MAP_IS_DELETING: "O mapa está sendo deletado...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_MAP:
          "Você tem certeza que deseja deletar esse mapa?",
      },
      CATEGORIES: {
        SEARCH_CATEGORIES: "Procurar Categoria",
        IN_ALL_FIELDS: "em todos os campos",
        ADD_CATEGORY: "Adicionar Categoria",
        SELECTED_RECORDS_COUNT: "Categorias selecionadas:",
        DELETE_ALL: "Deletar todas",
      },
      CATEGORY: {
        TITLE: "Categoria",
        EDIT_CATEGORY: "Editar Categoria",
        LAYERS: "Camadas",
        ORDER: "Ordem",
        TYPE: "Tipo",
        VISIBLE: "Visível",
        EXPAND_ON_INIT: "Expandir no início",
        USER_SID: "SID do Usuário",
        CONFIRM_EDIT: "Confirmar",
        SELECT_CATEGORY: "Selecionar Categoria",
        MOVE_CATEGORY: "Mover para outra Categoria",
        MOVING: "Movendo",
        MOVE: "Mover",
        UNCATEGORIZED: "Não Categorizado",
        ECOTX_CATEGORY: "Categoria da Ecotx",
        USER_CATEGORY: "Categoria do Usuário",
        BASIC_INFO: "Informações Básicas",
      },
      APPLY_STYLE: {
        APPLY_STYLE: "Aplicar estilo",
        SELECT_LAYER: "Camada",
        SELECT_GEOMETRY_STYLE: "Estilo de geometria",
        SELECT_GEOMETRY_PROPERTY: "Propriedade de geometria",
        SELECT_PROPERTY_VALUE: "Valor da propriedade de geometria",
        INTERVAL_START: "Início do intervalo",
        INTERVAL_END: "Fim do intervalo",
      },
      BASE_MAP: {
        BASE_MAP: "Mapa Base",
        BASE_MAPS_LIST: "Lista de Mapa Base",
        DISPLAY_ORDER: "Ordem de exibição",
      },
      EDIT_BASE_MAP: {
        BASEMAP: "Miniatura",
        BASE_MAP: "Mapa Base",
        FILE_UPLOAD: "Upload do Arquivo",
        URL: "URL de estilo",
        DISPLAY_ORDER: "Ordem de exibição",
        LOGO_URL: "Url da logo",
      },
      DELETE_BASE_MAP: {
        BASE_MAP_DELETE: "Excluir Mapa Base",
        BASE_MAP_IS_DELETING: "O Mapa Base está sendo excluído...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_BASE_MAP:
          "Tem certeza de que deseja excluir permanentemente este Mapa Base?",
      },
      DELETE_BASE_MAPS: {
        BASE_MAPS_DELETE: "Excluir Mapas Base",
        BASE_MAPS_IS_DELETING: "Os Mapas Base estão sendo excluídos...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_BASE_MAPS:
          "Tem certeza de que deseja excluir permanentemente os Mapas Base selecionados?",
      },
      FETCH_BASE_MAPS: {
        FETCH_SELECTED_BASE_MAPS: "Buscar os Mapas Base selecionados",
      },
      UPDATE_BASE_MAPS: {
        STATUS_UPDATE_FOR_SELECTED_BASE_MAPS:
          "Atualização de status para os Mapas Base selecionados",
      },
      LAYERS: {
        INFO_TAB: "Informações Básicas ",
        LVC_DATA: "Informações de Importação",
        PROPERTIES: "Propriedades",
        STYLES: "Estilos",
        LAYERS_LIST: "Lista de Camadas",
        IMPORT_STARTED: "Importação iniciada",
        IMPORT_COMPLETED: "Importação concluída",
        IN_ALL_FIELDS: "em todos os campos",
        IMPORT_FAILED: "Falha na importação",
        VALIDATION_IN_PROGRESS: "Validação em progresso",
        APPLYING_DICTIONARIES: "Aplicando dicionário",
        APPLYING_STYLES: "Aplicando estilos",
        CLEANUP_COMPLETED: "Limpeza concluída",
        PUBLISHED: "Publicadas",
        PREVIEW: "Prévia",
        STARTED: "Iniciada",
        REQUESTED: "Solicitada",
        PENDING_CLEANUP: "Limpeza pendente",
        IMPORT_IN_PROGRESS: "Importação em progresso",
        FAILED_TO_REQUEST: "Falha na solicitação",
        CLEANUP_IN_PROGRESS: "Limpeza em progresso",
        FAILED_FAST_IMPORT_PROCESS: "Falha no processo de importação rápida",
        FAILED_FAST_IMPORT_COMPLETED: "Falha na importação rápida concluída",
      },
      EDIT_LAYER: {
        UNABLE_TO_EDIT_OR_PUBLISH_THIS_LAYER:
          "Não é possível tratar ou publicar essa camada",
        STYLE_JSON: "Estilo em JSON",
        ZOOM_LOCK: "Zoom Lock",
        ZOOM_INTERACTION: "Zoom de Interação",
        BUCKET: "Bucket do minio",
        COUNT_GEOMETRY_DATA_PROD: "Contagem geométrica de dados em produção",
        COUNT_GEOMETRY_DATA_STAGE: "Contagem geométrica de dados em teste",
        COUNT_GEOMETRY_PROPERTY_PROD:
          "Contagem geométrica de propriedades em produção",
        COUNT_GEOMETRY_PROPERTY_STAGE:
          "Contagem geométrica de propriedades em teste",
        COUNT_GEOMETRY_PROPERTY_VALUE_PROD:
          "Contagem geométrica do valor em produção",
        COUNT_GEOMETRY_PROPERTY_VALUE_STAGE:
          "Contagem geométrica do valor em teste",
        COUNT_LAYER_PROD: "Contagem da camada em produção",
        COUNT_LAYER_STAGE: "Contagem da camada em teste",
        MESSAGE: "Mensagem",
        MINIO_PATH: "Caminho do minio",
        OWNER: "Dono",
        UPDATED: "Editada em",
        LVC_DETAILS: "Informações de importação",
        THERE_IS_ALREADY_A_PUBLISHED_VERSION:
          "Já existe uma versão publicada dessa layer",
        CANNOT_IMPORT_THE_LAYER: "Não é possível importar a camada",
        CHANGE_THE_LAYER_VERSION:
          "Para continuar é preciso mudar a camada dessa versão para a próxima.",
        THERE_ARE_ALREADY_LARGER_VERSIONS:
          "Não é possível importar a camada, pois, já existem versões maiores dessa camada já publicadas.",
      },
      DELETE_LAYER: {
        LAYER_DELETE: "Excluir Camada",
        LAYER_IS_DELETING: "A Camada está sendo excluída ...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_LAYER:
          "Tem certeza de que deseja excluir permanentemente esta Camada?",
      },
      DELETE_LAYERS: {
        LAYERS_DELETE: "Excluir Camadas",
        LAYERS_IS_DELETING: "As Camadas estão sendo excluídas ...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_LAYERS:
          "Tem certeza de que deseja excluir permanentemente as Camadas selecionadas?",
      },
      FETCH_LAYERS: {
        FETCH_SELECTED_LAYERS: "Buscar as Camadas selecionadas",
      },
      GEOMETRY: {
        MARKER: "Ícone",
        LINE_ADD: "Adicionar",
        LINE_CLEAR: "Limpar",
        RESET_MAP_VIEW: "Centralizar Mapa",
        CREATING: "Criando",
        CREATE_STYLE: "Criar Estilo",
        DELETING: "Deletando",
        ARE_U_SURE: "Tem certeza que deseja deletar o estilo selecionado?",
        DELETE_STYLE: "Deletar Estilo de Geometria",
        ROW_STYLE: "Estilo",
        NEW_STYLE: "Criar Estilo",
        STYLE_LIST: "Lista de Estilos de Geometria",
        TITLE: "Estilo da Geometria",
        GEOMETRY: "Geometria",
        POLYGON: "Polígono",
        POINT: "Ponto",
        LINE: "Linha",
        SYMBOL: "Símbolo",
        OPTION: "Opção",
        PROPERTY: "Propriedade",
        STYLE_TYPE: "Tipo de Estilo",
      },
      FILTER_EXPRESSION: {
        DISPLAY_ORDER: "Ordem de exibição",
        CONDITION_IS_REQUIRED: "Selecione uma condição",
        GEOMETRY_IS_REQUIRED: "Selecione uma propriedade",
        LAYER_IS_REQUIRED: "Camada é obrigatória",
        MAP_IS_REQUIRED: "Camada é obrigatório",
        ARE_U_SURE: "Tem certeza",
        EXPRESSIONS: "Expressões",
        MAP_AND_LAYER_TAB: "Mapa & Camada",
        SELECT_EXPRESSIONS: "Selecionar Expressões",
        GEOMETRY_PROP: "Propriedade Geométrica",
        ADD_GEOMETRY_PROP: "Adicionar Expressões",
        CONFIRM_EDIT: "Confirmar",
        ICON: "Ícone",
        NEW_FILTER_EXPRESSION: "Criar Filtro",
        CONDITION: "Condição",
        LAYER_NAME: "Nome da Camada",
        GEOMETRY_PROP_NAME: "nome da propriedade de geometria",
        TITLE: "Lista de Expressões de Filtro",
        ROW_NAME: "Nome da fileira",
        ACTIONS: "Ações",
        FILTER_EXPRESSION: "Filtro",
        DELETING: "Deletando",
      },
      DELETE_FILTER_EXPRESSION: {
        FILTER_EXPRESSIONS_DELETE: "Excluir Expressão de Filtro",
        FILTER_EXPRESSIONS_IS_DELETING:
          "A Expressão de Filtro está sendo excluída ...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_FILTER_EXPRESSIONS:
          "Tem certeza de que deseja excluir permanentemente esta Expressão de Filtro?",
      },
      DELETE_FILTER_EXPRESSIONS: {
        LAYERS_DELETE: "Excluir Expressões de Filtro",
        LAYERS_IS_DELETING: "As Expressões de Filtro estão sendo excluídas ...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_FILTER_EXPRESSIONS:
          "Tem certeza de que deseja excluir permanentemente as Expressões de Filtro selecionadas?",
      },
      MAP_LIST: {
        MAPS_LIST: "Lista de Mapas",
        NEW_MAP: "Novo Mapa",
        DELETE_MAP: "Excluir Mapa",
        MAPS: "Meus Mapas",
        WORKSPACES: "Meus Workspaces",
        CATEGORY_TABLE: "Categorias de Camadas",
        UNCATEGORIZED_LAYER: "Camadas sem Categoria",
      },
      EDIT_MAP: {
        WELCOME_MESSAGE: "Mensagem de boas-vindas",
        PUBLIC_MAP: "Mapa Público",
        HOST: "Host",
        BEARING: "Orientação",
        PITCH: "Inclinação",
        BASIC_INFO: "Informações Básicas",
        LAYERS: "Camadas",
        FILTERS: "Filtros",
        ORIENTED_SEARCHES: "Buscas Orientadas",
        SEARCH_FILTERS: "Procurar Filtros",
        ADD_ORIENTED_SEARCH: "Adicionar Busca Orientada",
        INITIAL_ZOOM: "Zoom Inicial",
        SELECT_BASEMAP: "Mapa Base",
        PLEASE_SELECT: "Por favor selecione um ",
        BASEMAP: "Mapa Base",
        CENTER_LATITUDE: "Latitude do Centro",
        CENTER_LONGITUDE: "Longitude do Centro",
        ROLE: "Cargo",
        ADD_LAYER: "Adicionar Camada",
        CATEGORIES: "Categorias",
        DISPLAY_ORDER: "Ordem de Exibição",
        NAME: "Nome",
        CANCEL: "Cancelar",
        SAVE: "Salvar",
        BACK: "Voltar",
        MAP: "Mapa",
      },
      FETCH_MAPS: {
        FETCH_SELECTED_MAPS: "Buscar os mapas selecionados",
      },
      IMPORT_LAYERS: {
        CLEAR_CACHE: "Limpar cache",
        NOT_STARTED: "Não iniciado",
        PENDING: "Pendente",
        FINISHED: "Finalizado",
        ERROR: "Erro",
        FILE_PATH: "Caminho do arquivo",
        SEE_BATCH: "Ver lotes",
        RETRACT_BATCH: "Retrair lotes",
        REAPPLY_STYLE: "Reaplicar estilo",
        WILL_IT_BE_APPLIED: "Será aplicada?",
        GEOMETRY_STYLE_IN_VERSION: "Estilo de geometria na versão",
        PROPERTY_VALUE_IN_VERSION: "Valor da propriedade na versão",
        PROPERTY_IN_VERSION: "Propriedade na versão",
        UNIQUE_STYLE: "Estilo Único",
        STYLE: "Estilo na versão",
        STYLE_TYPE: "Tipo de estilo na versão",
        STYLE_SETTINGS: "Configuraçoes de estilo",
        LOAD_STYLES_FROM_PREVIOUS_VERSIONS:
          "Carregar estilos de versões anteriores",
        DISPLAY_ORDER: "Ordem de exibição",
        IS_THERE_IN_THE_VERSION: "Existe na versão",
        CURRENT_LAYER_PROPERTIES: "Propriedades da layer atual",
        LOAD_PROPERTIES_FROM_PREVIOUS_VERSIONS:
          "Carregar propriedades de versões anteriores",
        PROPERTY_SETTINGS: "Configuraçoes de propriedade",
        USE_VERSION: "Usar versão",
        APPLY: "Aplicar",
        DICTIONARY_DESC: "Aplicar dicionário nas propriedades",
        STYLE_DESC: "Aplicar estilos na Camada",
        SUMMARY_DESC: "Resumo das mudanças feitas",
        NEXT_STEP: "Próximo",
        PREVIOUS: "Anterior",
        STYLE_DATAILS: "Detalhes do estilo",
        DICTIONARY_DATAILS: "Detalhes do dicionário",
        BY_DISPLAY_NAME: "por nome de exibição",
        BY_PROPERTIES: "Por propriedade",
        SETUP_STYLES: "Configure seus estilos",
        ORDER: "Ordem",
        VISIBLE: "Visível",
        RENAME_PROPS: "Renomeie as propriedades",
        SUMMARY: "Resumo",
        STYLES: "Estilos",
        DICTIONARY: "Dicionário",
        IMPORT_LAYERS_LIST: "Lista de Importação de Camadas",
        NEW_IMPORT_LAYER: "Importar Camada",
        OWNER: "Dono",
        STYLE_JSON: "Estilo em JSON",
        ZOOM_LOCK: "Zoom Lock",
        ZOOM_INTERACTION: "Zoom de Interação",
        IMPORT_LAYER_DELETE: "Excluir Camada",
        IMPORT_COMPLETED: "Importação concluída",
        FAILED_FAST_IMPORT_PROCESS: "Falha no processo de importação rápida",
        FAILED_FAST_IMPORT: "Falha na importação rápida concluída",
        PREVIEW: "Visualização",
        PREVIEW_LAYER: "Visualizar",
        REVIEW_LAYER: "Revisar",
        FAST_IMPORT_COMPLETED: "Importação Rápida Concluída",
        FAILED_TO_REQUEST: "Falha na Requisição",
        PUBLISHED: "Publicado",
        PROGRESS: "Progresso",
        STARTED: "Iniciado",
        IMPORT_IN_PROGRESS: "Importação em Progresso",
        PUBLISH_LAYER: "Publicar Camada",
        REQUESTED: "Requisitado",
        PENDING_CLEANUP: "Limpeza pendente",
        CLEANUP_IN_PROGRESS: "Limpeza em progresso",
        CLEANUP_COMPLETED: "Limpeza completa",
        UPDATE: "Atualizar",
      },
      CREATE_MAP_LAYER: {
        CREATE: "Adicionar Camada",
        PUBLISHED_LAYER: "Camadas Publicadas",
      },
      CREATE_IMPORT_LAYER: {
        MODAL_TITLE: "Importar Camada",
        IMPORT: "Importar",
        BOT: "Bot",
        MINIO: "MinIO",
        BATCH: "Lote",
        RASTER: "Raster",
        RASTER_NAME: "Nome do Arquivo Raster",
        RASTER_URL: "URL do Raster",
        TYPE: "Tipo da Importação",
        STYLE_JSON: "Estilo em JSON",
        ZOOM_LOCK: "Zoom Lock",
        ZOOM_INTERACTION: "Zoom de Interação",
        FILE: "Arquivo",
        FILE_UPLOAD: "Carregar arquivo",
        MINIO_BUCKET: "MinIO bucket",
        MINIO_PATH: "MinIO path",
        FILE_NAME: "Nome do arquivo",
      },
      DELETE_IMPORT_LAYER: {
        IMPORT_LAYER_DELETE: "Excluir Importação de Camada",
        IMPORT_LAYER_IS_DELETING:
          "A Importação de Camada está sendo excluída ...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_IMPORT_LAYER:
          "Tem certeza de que deseja excluir permanentemente esta Importação de Camada?",
      },
      ORIENTED_SEARCH: {
        NONE: "Nenhum",
        REMOVE: "Remover",
        ADD: "Adicionar",
        ORIENTED_SEARCH_LIST: "Lista de Busca Orientada",
        ADD_ORIENTED_SEARCH: "Adicionar Busca Orientada",
      },
      EDIT_ORIENTED_SEARCH: {
        ORIENTED_SEARCH: "Busca Orientada",
        LAYER: "Camada",
        BASIC_INFO: "Informações Básicas",
        GEOMETRY_PROPERTIES: "Propriedades de geometria",
      },
      DELETE_ORIENTED_SEARCH: {
        ORIENTED_SEARCH_DELETE: "Excluir Busca Orientada",
        ORIENTED_SEARCH_IS_DELETING: "A Busca Orientada está sendo excluída...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_ORIENTED_SEARCH:
          "Tem certeza de que deseja excluir permanentemente esta Busca Orientada?",
      },
      DELETE_MAP_LAYER: {
        MAP_LAYER_DELETE: "Deletar Camada do Mapa",
        MAP_LAYER_IS_DELETING: "A Camada do Mapa está sendo excluída...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_MAP_LAYER:
          "Você tem certeza que quer deletar permanentemente esta Camada?",
      },
      PUBLISH_IMPORT_LAYER: {
        MODAL_TITLE: "Publicar Camada",
      },
      PRODUCTS_LIST: {
        CREATE_PRODUCT: "Criar Produto",
        PRODUCTS_LIST: "Lista de Produtos",
        NEW_PRODUCT: "Novo Produto",
        NAME: "Nome",
        DESCRIPTION: "Descrição",
        ACTIVE: "Ativo",
        REQUIRED_ROLE: "Papel Necessário",
      },
      EDIT_PRODUCT: {
        WELCOME_MESSAGE: "Mensagem de boas-vindas",
        PUBLIC_MAP: "Mapa Público",
        HOST: "Host",
        BEARING: "Orientação",
        PITCH: "Inclinação",
        BASIC_INFO: "Informações Básicas",
        LAYERS: "Camadas",
        FILTERS: "Filtros",
        ORIENTED_SEARCHES: "Buscas Orientadas",
        SEARCH_FILTERS: "Procurar Filtros",
        ADD_ORIENTED_SEARCH: "Adicionar Busca Orientada",
        INITIAL_ZOOM: "Zoom Inicial",
        SELECT_BASEMAP: "Mapa Base",
        PLEASE_SELECT: "Por favor selecione um ",
        BASEMAP: "Mapa Base",
        CENTER_LATITUDE: "Latitude do Centro",
        CENTER_LONGITUDE: "Longitude do Centro",
        ROLE: "Cargo",
        ADD_LAYER: "Adicionar Camada",
        CATEGORIES: "Categorias",
        NAME: "Nome",
        CANCEL: "Cancelar",
        SAVE: "Salvar",
        BACK: "Voltar",
        PRODUCT: "Produto",
      },
      DELETE_PRODUCT: {
        PRODUCT_DELETE: "Deletar Produto",
        PRODUCT_IS_DELETING: "O produto está sendo deletado...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_PRODUCT:
          "Você tem certeza que deseja deletar esse Produto?",
      },

      CREATE_LAYER: {
        CREATE: "Adicionar Camada",
        PUBLISHED_LAYER: "Camadas Publicadas",
      },
      CATEGORY_LAYERS: {
        ACTIVE_ON_INIT: "Ativada ao iniciar",
        ACTIVATE_ON_INIT: "Ativar ao iniciar",
        DEACTIVATE_ON_INIT: "Desativar ao iniciar",
        SELECT_CATEGORY: "Selecionar Categoria",
        MOVE_CATEGORY: "Mover para outra Categoria",
        MOVING: "Movendo",
        MOVE: "Mover",
      },
      DELETE_CATEGORY: {
        MAP_CATEGORY_DELETE: "Deletar Categoria",
        MAP_CATEGORY_IS_DELETING: "A Categoria está sendo deletada...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_CATEGORY:
          "Você tem certeza que deseja deletar essa Categoria?",
      },
      MAP_ICONS: {
        MAP_ICONS: "Ícones do mapa",
        MAP_ICON: "Ícone do Mapa",
        MAP_ICON_DELETE: "Deletar Ícone de Mapa",
        MAP_ICON_IS_DELETING: "O Ícone de Mapa está sendo deletado...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_MAP_ICON:
          "Você tem certeza que deseja deletar esse Ícone de Mapa?",
      },
    },
    ORDERS: {
      SELECTED_AREA: "Área selecionada",
      RETENTION_END_PERIOD: "Período final de retenção",
      TOTAL_LEADS: "Total de Leads",
      LEADS_DETAILS: "Detalhes do Leads",
      IN_PROGRESS: "Em progresso",
      ERROR: "Erro",
      SUCCESS: "Sucesso",
      PENDING: "Pendente",
      TRIM_EXPORTER: "Exporter",
      LEADS: "Leads",
      ORDER_CREATED: "Data de criação",
      TRIM_DETAILS: "Detalhes do Exporter",
      DISPLAY_NAME: "Nome",
      IS_OUTDATED: " Está desatualizado",
      MEDIA_FILE_URL: "Link para o arquivo",
      UPDATE_DATE: "Data de atualização",
      LAYER_NAME: "Nome da camada",
      PRICE: "Preço",
      FALSE: "Não",
      TRUE: "Sim",
    },
    PAYMENTS_MANAGEMENT: {
      DETAILS: "Detalhes do pedido",
      TRIM_DETAILS: "Detalhes do Exporter",
      LEADS_DETAILS: "Detalhes do Leads",
      DISPLAY_NAME: "Nome",
      TOTAL_LEADS: "Total de Leads",
      PRICE: "Preço",
      RETENTION_END_PERIOD: "Período final de retenção",
      STATUS: "Status",
      MEDIA_FILE_URL: "Link para o arquivo",
      SELECTED_AREA: "Área selecionada",
      IS_OUTDATED: " Está desatualizado",
      LAYER_NAME: "Nome da camada",
      UPDATE_DATE: "Data de atualização",

      ORDER: {
        CANCELED: "Cancelado",
        CONFIRM: "Confirmar",
        CANCEL_ORDER: "Cancelar pedido",
        CANCEL: "Cancelar",
        ORDER_IS_BEING_CANCELED: "O pedido está sendo cancelado",
        ARE_YOU_SURE_TO_CANCEL_ORDER:
          "Tem certeza que deseja cancelar o pedido?",
        APPROVE: "Aprovar",
        APPROVE_ORDER: "Aprovar pedido",
        ORDER_IS_BEING_APPROVED: "O pedido está sendo aprovado",
        ARE_YOU_SURE_TO_APPROVE_ORDER:
          "Tem certeza que deseja aprovar o pedido?",
        USER_EMAIL: "Email do usuário",
        ORDER_NUMBER: "Número do pedido",
        ORDER_PRICE: "Valor do pedido",
        SUCCESS: "Sucesso",
        ERROR: "Erro",
        PENDING: "Pendente",
        PRODUCT: "Produto",
        MESSAGE: "Mensagem",
        CREATED: "Data de criação",
        MODIFIED: "Data da ultima modificação",
      },

      COUPON: {
        COUPON: "Cupom",
        COUPONS: "Cupons",
        COUPON_LIST: "Lista de Cupons",
        COUPON_NAME: "Nome do Cupom",
        COUPON_CODE: "Código do Cupom",
        DISABLE_ALL: "Desabilitar todos os selecionados",
        TYPE: "Tipo",
        SELECTED_RECORDS_COUNT: "Cupons selecionados:",
      },
      FETCH_COUPON: {
        FETCH_SELECTED_COUPONS: "Buscar os Cupons selecionados",
      },
      EDIT_COUPON: {
        COUPON_NAME: "Nome do Cupom",
        COUPON_CODE: "Código do Cupom",
        DISCOUNT: "Desconto",
        DAYS_TO_TRIAL: "Dias de Teste",
        MAX_USAGE_COUNT: "Quantidade Máxima de Usos",
        SUBSCRIPTION: "Assinatura",
        TRIAL: "Dias de Período de Teste",
        PERCENT_DISCOUNT: "Porcentagem de Desconto",
        DIRECT_DISCOUNT: "Desconto Direto",
      },
      DISABLE_COUPON: {
        COUPON_DISABLE: "Desabilitar Cupom",
        ARE_YOU_SURE_TO_DISABLE_THIS_COUPON:
          "Você tem certeza que deseja desabilitar este Cupom?",
        COUPON_IS_BEING_DISABLED: "Desabilitando Cupom",
      },
      DISABLE_COUPONS: {
        COUPONS_DISABLE: "Desabilitar Cupons",
        ARE_YOU_SURE_TO_DISABLE_SELECTED_COUPONS:
          "Você tem certeza que deseja desabilitar estes Cupons?",
        COUPONS_ARE_BEING_DISABLED: "Desabilitando Cupons",
      },
      PAYMENTS: {
        TRIAL: "Período de Teste",
        CREDIT_CARD: "Cartão de crédito",
        BANK_SLIP: "Boleto Bancário",
        FAILED: "Falhou",
        PROCESSED: "Processado",
        REQUESTED: "Requisitado",
        PAYMENTS_LIST: "Lista de Pagamentos",
        USERNAME: "Nome de Usuário",
        SUBSCRIPTION_NAME: "Nome da Assinatura",
        STATUS: "Status",
        PRICE: "Preço",
        CREATED: "Data de criação",
        TYPE: "Tipo",
      },
      FETCH_PAYMENTS_LIST: {
        FETCH_PAYMENT: "Buscar Pagamento",
      },
      VIEW_PAYMENT: {
        PAYMENT: "Pagamento",
        USER_SID: "SID do Usuário",
        SUBSCRIPTION_SID: "SID da Assinatura",
        COUPON_CODE_SID: "SID do código de Cupom",
        USER_SUBSCRIPTION_SID: "SID da Assinatura do usuáiro",
        TYPE: "Tipo",
        STATUS: "Status",
        PRICE: "Preço",
        PAID_INSTALLMENTS: "Parcelas pagas",
        TOTAL_INSTALLMENTS: "Parcelas totais",
        BOLETO_URL: "URL do boleto",
        BOLETO_BAR_CODE: "Código de barras do boleto",
        COMMENTS: "Comentários",
        CREDIT_CARD_HASH: "Hash do cartão de crédito",
        CREDIT_CARD_ID: "ID do cartão de crédito",
        SUBSCRIPTION_NAME: "Nome da Assinatura",
        COUPON_CODE_NAME: "Nome do Cupom",
        USER_NAME: "Nome de Usuário",
        RECURRENCE_TYPE: "Tipo de Cobrança",
        EXTERNAL_INVOICE_ID:"ID da Fatura Externa",
        EXTERNAL_INVOICE_URL:"URL da Fatura Externa",
        ORDER_ID:"ID do Pedido",
        ORDER_CODE:"Código do Pedido",
        PRODUCT:"Produto"
      },
      SUBSCRIPTIONS: {
        SELECTED_RECORDS_COUNT: "Assinaturas selecionadas:",
        DISABLE_ALL: "Desabilitar selecionados",
        SUBSCRIPTIONS_LIST: "Lista de Assinaturas",
        MONTHLY_PRICE: "Preço mensal",
        YEARLY_PRICE: "Preço anual",
        NEW_SUBSCRIPTION: "Nova Assinatura",
      },
      EDIT_SUBSCRIPTION: {
        SUBSCRIPTION: "Assinatura",
        MONTHLY_PRICE: "Preço mensal",
        YEARLY_PRICE: "Preço anual",
        KEYCLOAK_ROLES: "Cargos no Keycloak",
      },
      FETCH_SUBSCRIPTION: {
        FETCH_SELECTED_SUBSCRIPTIONS: "Buscar as Assinaturas selecionadas",
      },
      DISABLE_SUBSCRIPTION: {
        SUBSCRIPTION_DISABLE: "Desabilitar Assinatura",
        ARE_YOU_SURE_TO_DISABLE_THIS_SUBSCRIPTION:
          "Você tem certeza que deseja desabilitar esta Assinatura?",
        SUBSCRIPTION_IS_DISABLING: "A Assinatura está sendo desabilitada.",
      },
      DISABLE_SUBSCRIPTIONS: {
        SUBSCRIPTIONS_DISABLE: "Desabilitar Assinatura",
        ARE_YOU_SURE_TO_DISABLE_SELECTED_SUBSCRIPTIONS:
          "Você tem certeza que deseja desabilitar as Assinaturas selecionadas?",
        SUBSCRIPTIONS_ARE_DISABLING:
          "As Assinaturas estão sendo desabilitadas.",
      },
    },
    SUBSCRIPTION_MANAGEMENT: {
      SUBSCRIPTION: {
        SUBSCRIPTIONS_DISABLE: "Desabilitar Assinatura",
        ARE_YOU_SURE_TO_DISABLE_SELECTED_SUBSCRIPTIONS:
          "Você tem certeza que deseja desabilitar as Assinaturas selecionadas?",
        SUBSCRIPTIONS_ARE_DISABLING:
          "As Assinaturas estão sendo desabilitadas.",
        SELECT_USER: "Selecionar Usuário",
        SELECT_SUBSCRIPTION: "Selecionar Assinatura",
        COMPLETE: "Concluir",
        EXISTING_USER: "Usuário Existente",
        NEW_USER: "Novo Usuário",
        SELECT_EXISTING_USER: "Selecione usuário existente",
        EMAIL: "Email",
        CPF_CNPJ: "CPF/CNPJ",
        NAME: "Nome",
        PHONE: "Telefone",
        MONTHLY: "Mensal",
        YEARLY: "Anual",
        SUBSCRIPTION: "Assinatura",
        EXPIRY_DATE: "Data de Vencimento",
        ACCESS: "Acessos",
        MONTHLY_VALUE: "Valor Mensal da Assinatura",
        YEARLY_VALUE: "Valor Anual da Assinatura",
        BILLING_NAME: "Nome de Cobrança",
        BILLING_MAIL: "Email de Cobrança",
        BILLING_CPF: "CPF/CNPJ de Cobrança",
        BILLING_PHONE_NUMBER: "Telefone de Cobrança",
      },
    },
  },
};
